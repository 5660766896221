/* Page Banner / Breadcrumb */
.page-banner-section {
    position: relative;

    padding: 30px 0;
    background-color: $gray-900;
    /*background-image: url('/images/page-banner-bg.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;*/
}
