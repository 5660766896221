.product-carousel {
    margin: -15px;
    padding: 15px;
    @media #{$large-mobile} {
        margin-bottom: 0;
        padding-bottom: 40px;
    }
    &:hover {
        & .swiper-button-prev,
        & .swiper-button-next {
            visibility: visible;

            opacity: 1;
        }
        & .swiper-button-prev {
            left: 0;
        }
        & .swiper-button-next {
            right: 0;
        }
    }
}

@include media-breakpoint-up(xl)  {
	.product-carousel {
		.product-title {
			height: 70px;
			overflow: hidden;
		}
		.swiper-wrapper {
			align-items: end;
		}
		.swiper-slide{
			height: inherit;
			padding-right: 30px;
			padding-left: 30px;
		}
		// .center jedan veci box
		.swiper-slide-active {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}

		.swiper-slide-visible {
			padding-left: 0;
			padding-right: 30px;
		}
		.swiper-slide-visible ~ .swiper-slide-visible{
			padding-right: 30px;
			padding-left: 0;
		}
		.swiper-slide-visible ~ .swiper-slide-visible ~ .swiper-slide-visible ~ .swiper-slide-visible {
			padding-right: 0;
			padding-left: 30px;
		}
		.swiper-slide-visible ~ .swiper-slide-visible ~ .swiper-slide-visible ~ .swiper-slide-visible ~ .swiper-slide-visible {
			padding-right: 0;
			padding-left: 30px;
		}
	}
}