.breadcrumb-item + .breadcrumb-item::before {
  content: none !important;
}

.list-group {


}

.form-group {
    label {
        margin-bottom: 5px;
    }
    margin-bottom: 15px;
}

.form-row {
	margin-bottom: 15px;
}

