/* Button CSS */
.btn {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    padding: 20px 40px;

    text-align: center;
    white-space: nowrap;

    border-width: 2px;
    border-radius: 0;

    gap: 6px;
    @media #{$tablet-device,
    $desktop-device} {
        padding: 16px 35px;

        gap: 12px;
    }
    @media #{$large-mobile} {
        font-size: 14px;

        padding: 13px 25px;

        gap: 8px;
    }
    &:focus {
        box-shadow: none;
    }
    & i {
        align-self: center;
    }
    &-icon {
        padding: 20px;
        @media #{$tablet-device,
        $desktop-device} {
            padding: 16px;
        }
        @media #{$large-mobile} {
            padding: 13px;
        }
    }
    /* Small Button */
    &-sm {
        font-size: 14px;

        padding: 13px 25px;

        gap: 8px;
    }
    // Close Button
    &-close {
        position: relative;

        opacity: 1;
        color: $headings-color;
        &:focus {
            box-shadow: none;
        }
        &::before,
        &::after {
            position: absolute;
            top: 50%;
            left: 50%;

            width: 22px;
            height: 2px;
            margin-top: -1px;
            margin-left: -11px;

            content: "";

            border-radius: 2px;
            background-color: currentColor;
        }
        &::before {
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);
        }
        &:hover {
            opacity: 1;
            color: $primary;
        }
    }
    // Theme
    &-primary {
        color: $white;
    }
    // Outline
    &-outline {
        &-light {
            color: $white;
            border-color: $border-color;
        }
    }
    // Hover
    @each $name, $value in $theme-colors {
        &-#{$name}-hover {
            &:hover {
                border-color: $value;
                background-color: $value;
                @if $name == "warning" or $name == "info" or $name == "light" {
                    color: $black;
                } @else {
                    color: $white;
                }
            }
        }
    }
}
