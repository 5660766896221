
.main-slide-1 {
    display: flex;
    align-items: center;

    height: 750px;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @media #{$laptop-device} {
        height: 527px;
    }
    @media #{$desktop-device} {
        height: 550px;
    }
    @media #{$tablet-device} {
        height: 500px;
    }
    @media #{$large-mobile} {
        height: 350px;
    }
    @media #{$small-mobile} {
        padding: 30px 0;
    }
    &-content {
        max-width: 670px;
        @media #{$laptop-device} {
            max-width: 450px;
        }
        @media #{$desktop-device} {
            max-width: 500px;
        }
        @media #{$tablet-device} {
            max-width: 400px;
        }
        @media #{$large-mobile} {
            max-width: 285px;
        }
        & > * {
            transition-timing-function: ease;
            transition-duration: 1s;
            transition-property: all;
            transform: translateX(-100px);

            opacity: 0;
            &:nth-child(1) {
                transition-delay: 0.5s;
            }
            &:nth-child(2) {
                transition-delay: 0.8s;
            }
            &:nth-child(3) {
                transition-delay: 1.1s;
            }
            &:nth-child(4) {
                transition-delay: 1.4s;
            }
        }
    }
    &-title {
        font-size: 100px;
        font-weight: 600;
        line-height: 1.125;

        margin-bottom: 25px;
        @media #{$laptop-device} {
            font-size: 60px;
        }
        @media #{$desktop-device} {
            font-size: 65px;
        }
        @media #{$tablet-device} {
            font-size: 54px;
        }
        @media #{$large-mobile} {
            font-size: 36px;

            margin-bottom: 15px;
        }
        @media #{$small-mobile} {
            font-size: 30px;
        }
    }
    &-text {
        font-size: 18px;
        @media #{$laptop-device,
        $desktop-device,
        $tablet-device} {
            font-size: 16px;
        }
        @media #{$large-mobile} {
            font-size: 14px;
        }
        &:not(:last-child) {
            margin-bottom: 30px;
            @media #{$large-mobile} {
                margin-bottom: 20px;
            }
        }
    }
    &-image {
        transition-delay: 0.5s;
        transition-timing-function: ease;
        transition-duration: 1s;
        transition-property: all;
        transform: translateX(-100px);

        opacity: 0;
    }
}
/* Active Slide Animation */
.swiper-slide-active {
    & .main-slide-1 {
        &-content {
            & > * {
                transform: translateX(0);

                opacity: 1;
            }
        }
        &-image {
            transform: translateX(0);

            opacity: 1;
        }
    }
}

.slide-dark {
    & .main-slide-1 {
        &-title {
            color: #fff;
        }
        &-content {
            color: #fff;
        }
    }
}
