.owl-carousel {

	.owl-stage {
		//max-height: 200px;
		display: flex;
		align-items: end;
	}
	.owl-item {
		margin-bottom: 2px;
		margin-left: 2px;
	}
	// .center jedan veci box
	.owl-item.center {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.owl-item.active {
		padding-right: 30px;
	}
	.owl-item.active ~ .owl-item.active{
		padding-right: 30px;
	}
	.owl-item.active ~ .owl-item.active ~ .owl-item.active ~ .owl-item.active {
		padding-right: 0;
		padding-left: 30px;
	}
	.owl-item.active ~ .owl-item.active ~ .owl-item.active ~ .owl-item.active ~ .owl-item.active {
		padding-right: 0;
		padding-left: 30px;
	}
	.owl-dots {
		.owl-dot span{
		    width: 8px;
		    height: 8px;
		    background-color: #bcbcbc;
		    display: inline-block;
		    border-radius: 50%;
		}

		.owl-dot.active span{
			background-color:$orange;
		    width: 30px;
		    height: 8px;
		    border-radius: 3px;
		}
	}
	.owl-dots .owl-dot + .owl-dot {
	    margin-left: 1px;
	}

	.owl-nav {
		position: absolute;
		top: 50%;
		z-index: 1001;
		display: block;
		left: 0;
		right: 0;
		margin: 0px auto;
		.owl-prev {
		    left: -60px;
		}

		.owl-next {
		    right: -60px;
		}

		.owl-next, 
		.owl-prev {
		    position: absolute;
		    top: calc(50% - 6px);
		    transform: translateY(-50%);
		    z-index: 9999;
			color: #ffffff !important;
			width: 50px;
			height: 50px;
			line-height: 50px;
			-webkit-border-radius: 50px;
			-moz-border-radius: 50px;
			border-radius: 50px;
			background: $primary !important;
			text-align: center;
		    i {
		    	font-weight: 900;
		    }
		}

		[class*="owl-"]:hover {
		    background: $orange !important;
		    color: #fff;
		    text-decoration: none;
		}
	}
/*
	// Small
	.owl-nav {

		.owl-prev {
		    left: -20px;
		}

		.owl-next {
		    right: -20px;
		}

		.owl-next, 
		.owl-prev {
		    position: absolute;
		    top: calc(50% - 6px);
		    transform: translateY(-50%);
		    z-index: 9999;
		    i {
		    	font-weight: 900;
		    }
		}

		[class*="owl-"]:hover {
		    background: transparent;
		    color: #000;
		    text-decoration: none;
		}
	}

*/

}

@include media-breakpoint-down(lg)  {
	.owl-carousel {
		.owl-item.active {
			padding-right: 30px;
		}
		.owl-item.active ~ .owl-item.active{
			padding-right: 0;
			padding-left: 0;
		}
		.owl-item.active ~ .owl-item.active ~ .owl-item.active  {
			padding-right: 0;
			padding-left: 30px;
		}
		.owl-nav {
			position: relative;
			top: auto;
			margin-top: 30px;
			.owl-prev {
			    left: auto;
			}

			.owl-next {
			    right: auto;
			}

			.owl-next, 
			.owl-prev {
			    position: relative;
				top: auto;
			}
		}


	}
}


@include media-breakpoint-down(md)  {
	.owl-carousel {
		.owl-item.active {
			padding-right: 30px !important;
			padding-left: 30px !important;
		}


	}
}