.extreme-cookie {
    background: $gray-900;
    bottom: 0;
    color: #FFFFFF;
    font-family: 'Arial';
    font-size: 14px;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 9999999999;

    .extreme-cookie-content {
        margin: 0 auto;
        max-width: 90%;
        padding: 20px;
        width: 100%;
    }
    .extreme-cookie-content::after {
        clear: both;
        content: '';
        display: block;
    }

    .extreme-cookie-panel-toggle,
    .extreme-cookie-settings,
    .extreme-cookie-policy,
    .extreme-cookie-btn,
    .extreme-cookie-settings .icon,
    .extreme-cookie-switch {
        transition: all .3s ease;
    }

    .extreme-icon {
        font-size: 25px;

    }

    .extreme-cookie i.fa-cookie-bite {
        font-size: 24px !important;
    }

    .extreme-cookie-panel-toggle {
        background: inherit;
        border-top: solid 3px #FFFFFF;
        cursor: pointer;
        padding: 10px;
        position: absolute;
        transform: translateY(-100%);
    }
    .extreme-cookie-panel-toggle::before {
        background: #222;
        content: 'Uključite / isključite kolačiće';
        float: left;
        height: 20px;
        line-height: 25px;
        margin-right: 0;
        opacity: 0;
        overflow: hidden;
        position: relative;
        text-align: center;
        transition: all 0.3s ease;
        visibility: hidden;
        width: 0;
    }
    .extreme-cookie-panel-toggle:hover::before {
        opacity: 1;
        visibility: visible;
        margin-right: 10px;
        width: 190px;
    }
    .extreme-cookie-panel-toggle-left   { left: 10px; }
    .extreme-cookie-panel-toggle-center { left: calc(50% - 22.5px); }
    .extreme-cookie-panel-toggle-right  { right: 10px; }
    .extreme-cookie-panel-toggle:hover {
        border-top-color: #20BF11;
    }

    .extreme-cookie-message {
        float: left;
        line-height: 30px;
        width: 70%;
    }
    .extreme-cookie-decision {
        text-align: right;
        float: left;
        width: 30%;
    }
    .extreme-cookie-btn {

        margin-top:-8px !important;
    }
    .extreme-cookie-settings-icons {
        display: inline-block;
    }
    .extreme-cookie-settings,
    .extreme-cookie-policy {
        display: inline-block;
        line-height: 30px;
        margin-left: 13px;
        text-align: center;
        width: 20px;
    }
    .extreme-cookie-settings .icon,
    .extreme-cookie-policy .icon {
        margin-top: -5px;
        opacity: .7;
        position: relative;
        top: 7px;
    }
    .extreme-cookie-settings:hover .icon,
    .extreme-cookie-policy:hover .icon {
        opacity: 1;
    }
    .extreme-cookie-settings {
        cursor: pointer;
    }
    .extreme-cookie-details {
        clear: both;
        display: none;
        padding-top: 15px;
    }
    .extreme-cookie-details::after {
        clear: both;
        content: '';
        display: block;
    }
    .extreme-cookie-details-title {
        font-weight: bold;
    }
    .extreme-cookie-toggle {
        border: solid 1px;
        box-sizing: border-box;
        float: left;
        margin-top: 10px;
        padding: 5px;
        width: calc(50% - 5px);
    }
    .extreme-cookie-toggle:nth-child(even) {
        margin-right: 5px;
    }
    .extreme-cookie-toggle:nth-child(odd) {
        margin-left: 5px;
    }
    .extreme-cookie-name {
        cursor: pointer;
        float: left;
        line-height: 23px;
        padding-left: 15px;
        width: 80%;
    }
    .extreme-cookie-toggle input[type="checkbox"] {
        cursor: pointer;
        float: left;
        height: 25px;
        margin: 0;
        width: 20%;
    }

    .extreme-cookie-tooltip-trigger {
        position: relative;
    }
    .extreme-cookie-tooltip {
        display: none;
        animation: fadeIn .5s ease-in-out;
        background: #fff;
        border: solid 1px #000;
        color: #000;
        font-size: 11px;
        left: 50%;
        line-height: 23px;
        padding: 0 10px;
        position: absolute;
        top: 0;
        transform: translate(-50%, -100%);
        width: 130px;
    }
    .extreme-cookie-tooltip-trigger:hover {
        .extreme-cookie-tooltip {
            display:block !important;
        }
    }
    .extreme-cookie-switch.checked {
        background: #20BF6B;
    }
    .extreme-cookie-switch.disabled {
        cursor: not-allowed;
        opacity: .5;
    }
    .extreme-cookie-switch input {
        position: absolute;
        opacity: 0;
        width: 40px !important;
        z-index: 2;
    }
    .extreme-cookie-switch div {
        background: #FFFFFF;
        border-radius: .8em;
        box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
        height: .8em;
        width: 1em;

        -webkit-transition: all 300ms;
         -moz-transition: all 300ms;
              transition: all 300ms;
    }

    .extreme-cookie-switch input:checked + div {
        -webkit-transform:  translate3d(100%, 0, 0);
        -moz-transform:     translate3d(100%, 0, 0);
                 transform: translate3d(100%, 0, 0);
    }
    .extreme-cookie-switch {
        background: #BDB9A6;
        border-radius: 1em;
        cursor: pointer;
        display: inline-block;
        font-size: 20px;
        height: .8em;
        margin: 0;
        position: relative;
        top: 4px;
        width: 2em;
    }
}


.extreme-cookie:not(.extreme-cookie-out) {
    animation: slideIn .5s ease-in-out;
}
.extreme-cookie.extreme-cookie-out {
    transform: translateY(100%);
    .extreme-cookie-panel-toggle-left {
        transform: translateY(100%);
    }
}
.extreme-cookie.extreme-cookie-slide-out {
    animation: slideOut .5s ease-in-out;
    transform: translateY(100%);
}


/* Animations */
@-webkit-keyframes fadeIn {
    from { opacity: 0; visibility: hidden; }
    to   { opacity: 1; visibility: visible; }
}
@keyframes fadeIn {
    from { opacity: 0; visibility: hidden; }
    to   { opacity: 1; visibility: visible; }
}
@-webkit-keyframes slideIn {
    from { transform: translateY(100%); }
    to   { transform: translateY(0%); }
}
@keyframes slideIn {
    from { transform: translateY(100%); }
    to   { transform: translateY(0%); }
}
@-webkit-keyframes slideOut {
    from { transform: translateY(0%); }
    to   { transform: translateY(100%); }
}
@keyframes slideOut {
    from { transform: translateY(0%); }
    to   { transform: translateY(100%); }
}




@media (max-width: 767px) {

    .extreme-cookie  {
        .extreme-cookie-content {
            max-width: 100%;
        }
        .extreme-cookie-decision {
            text-align: left;
            padding-left: 20px;
            .extreme-cookie-btn {
                margin-top: auto !important;
                display: block;
                width:100%;
            }
            .extreme-cookie-settings-icons {
                display: flex;
                align-content: center;
                justify-content: center;
            }
            .extreme-cookie-settings,
            .extreme-cookie-policy {
                margin:15px 20px 0 0;
                display:inline-block;
            }
            .extreme-cookie-policy {
                margin:15px 0 0 0;
            }
        }


    }

}
@media (max-width: 460px) {

    .extreme-cookie  {
        .extreme-cookie-message {
            width: 100%;
            text-align: center;
        }
        .extreme-cookie-decision {
            width: 100%;

            .extreme-cookie-btn {
                margin-top: 15px !important;
            }
        }
        .extreme-cookie-toggle {
            width:100%;
        }
        .extreme-cookie-toggle:nth-child(2n+1) {
            margin-left: 0;
        }
    }

}