/* Sections */
section {
	padding: 30px 0;
}

.section-heading {
    margin-top: 0px;
    font-size: 2rem;
    color: $black;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.5px;
	a {
	    color: $primary;
	}
	a:hover {
	    color: $secondary;
	}
}


.heading-border {
	position: relative;
	border-bottom: 1px solid $border-color;
	background-color: $main;
	margin-bottom: 30px;
}

.heading-border::before {
    position: absolute;
    bottom: -1px;
    width: 60px;
    height: 4px;
    background-color: inherit;
    content: '';
}

.heading-border::after {
    position: absolute;
    bottom: -1px;
    width: 25px;
    height: 4px;
    background-color: inherit;
    content: '';
    left: 63px;
}

.section-subheading {
	font-size: 1.125rem;
	font-weight: 400;
	margin-bottom: 45px;
    margin-top: 5px;
	text-transform: none;
}
/* Header Search */
.header-search-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .searchbox-container {
    width:100%;


  }


  .header-search-form {
      position: relative;
      //margin-top:100px;
      width: 100%;
      //max-width: 770px;
      & input[type="text"],
      & input[type="search"] {
          font-size: 20px;
          font-weight: 400;

          display: block;

          width: 100%;
          height: 50px;
          padding: 0;
          padding-right: 55px;
          padding-left: 10px;

          color: silver;
          border-width: 1px;
          border-style: solid;
          border-color: #6E6E6E;
          background-color: transparent;
      }
      & button.searchbox-button {
          font-size: 20px;

          position: absolute;
          top: 0;
          right: 0;

          width: 50px;
          height: 50px;
          padding: 0;

          color: $white;
          border: none;
          background-color: #000;
          &:hover {
              color: $primary;
          }
      }
      & button.clearsearch-button {
          font-size: 20px;
          position: absolute;
          top: 0;
          right: 50px;

          width: 50px;
          height: 50px;
          padding: 0;

          color: $black;
          border: none;
          background-color: transparent;
          &:hover {
              color: $gray-800;
          }
      }
  }



}
@include media-breakpoint-down(lg) {
.header-search-container {
    margin: 10px 0;
}

}