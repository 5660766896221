/* Hero Slide Three */
.articles-slide {
    display: flex;
    align-items: center;

    height: 600px;

    background-color: #F9F6F2;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @media #{$desktop-device} {
        height: 600px;
    }
    @media #{$tablet-device} {
        height: 500px;
    }
    @media #{$large-mobile} {
        height: 400px;
    }
    @media #{$small-mobile} {
        height: auto;
        padding: 70px 0;
    }
    &-content {
        max-width: 700px;
        margin: auto;
        padding: 50px;

        text-align: center;

        background: rgba(0,0,0,0.7);
        box-shadow: 0 0 0 21px #F1F1F1;



        @media #{$large-mobile} {
            max-width: calc(100% - 30px);
            padding: 30px;

            box-shadow: 0 0 0 15px #F1F1F1;
        }
        @media #{$small-mobile} {
            max-width: calc(100% - 60px);
        }
        @media #{$extra-small-mobile} {
            max-width: calc(100% - 30px);
            padding: 20px;
        }
        & > * {
            transition-timing-function: ease;
            transition-duration: 1s;
            transition-property: all;
            transform: translateY(100px);

            opacity: 0;
            &:nth-child(1) {
                transition-delay: 0.5s;
            }
            &:nth-child(2) {
                transition-delay: 0.8s;
            }
            &:nth-child(3) {
                transition-delay: 1.1s;
            }
            &:nth-child(4) {
                transition-delay: 1.4s;
            }
        }
    }
    &-sub-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.25;

        display: block;

        margin-bottom: 10px;

        text-transform: uppercase;

        color: $white;
    }
    &-title {
        font-size: 44px;
        font-weight: 500;
        color: $white;

        margin-bottom: 15px;
        @media #{$large-mobile} {
            font-size: 36px;
        }
        @media #{$small-mobile} {
            font-size: 30px;
        }
    }
    &-text {
        @media #{$large-mobile} {
            font-size: 14px;
        }
        &:not(:last-child) {
            margin-bottom: 30px;
            @media #{$large-mobile} {
                margin-bottom: 20px;
            }
        }
    }
}


/* Active Slide Animation */
.swiper-slide-active {
    & .articles-slide {
        &-content {
            & > * {
                transform: translateY(0);

                opacity: 1;
            }
        }
    }
}
