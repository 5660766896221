/* Colors */

$black:    #000 !default;
$white:    #fff !default;
$gray-100: #f4f4f4 !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #222529 !default;
$gray-900: #282828 !default;

//===============================
// Bootstrap Variables Overright
//===============================

// Color
$primary: #9c7a5c;
$secondary: $gray-600;
$border-color: #E5E5E5;
//
$body-color: #333333;
$headings-color: #212423;


$main: $primary;
$text: $gray-800;
$text-light: $gray-100;
$heading: $primary;
$background: $gray-900;
$background-light: $gray-100;

$upper-navbar: $gray-100;
$upper-navbar-text: $gray-800;

// Font Family
$oswald: "Oswald", sans-serif;
$open-sans: "Open Sans", sans-serif;

/* Fonts */
$font-family-base:      $open-sans;
$font-family-heading:   $oswald;



$enable-rounded: false;

// Responsive Variables
$extra-big-device: "only screen and (min-width: 1550px)";
$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1549px)";
$desktop-device: "only screen and (min-width: 992px) and (max-width: 1199px)";
$tablet-device: "only screen and (min-width: 768px) and (max-width: 991px)";
$large-mobile: "only screen and (max-width: 767px)";
$small-mobile: "only screen and (max-width: 575px)";
$extra-small-mobile: "only screen and (max-width: 479px)";


// Options
$enable-negative-margins: true;

// Spacing
$spacer: 1rem;

$spacers: (
  0: 0,
  1: $spacer * 0.3125,
  2: $spacer * 0.625,
  3: $spacer * 0.9375,
  4: $spacer * 1.25,
  5: $spacer * 1.625,
  6: $spacer * 1.875,
  7: $spacer * 2.1875,
  8: $spacer * 2.5,
  9: $spacer * 2.8125,
  10: $spacer * 3.125,
);

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
  xxl: 1200px,
);

//
$grid-gutter-width: 30px;
//
$gutters: $spacers;
// Transition
$transition-base: all 0.4s ease 0s;
// Typography
$font-family-base: $open-sans;
$font-size-base: 1rem;
$line-height-base: 1.75;
//
$headings-font-family: $oswald;
$headings-font-weight: 500;
$headings-line-height: 1.25;


@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';